.body-cta {
  background: var(--xs-body-cta-background);
  color: var(--xs-body-cta-color);

  @media (min-width: 768px) {
    background: var(--sm-body-cta-background);
  }

  .container {
    padding: 50px;

    @media (min-width: 768px) {
      background: var(--sm-body-cta-container-background);
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      padding: 40px 30px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 30px 25px;
    }

    @media (max-width: 767px) {
      padding: 20px;
      text-align: center;
    }
  }

  h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    margin: 0 0 8px;

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 26px;
      line-height: 28px;
      margin-bottom: 14px;
    }

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 25px;
      margin-bottom: 10px;
    }
  }

  p {
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    margin: 0;

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 15px;
    }

    a {
      color: var(--xs-body-cta-link-color);

      &:hover {
        color: var(--xs-body-cta-link-hover-color);
      }
    }
  }

  .btn-danger {
    color: var(--xs-body-cta-button-color);
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    border: var(--border-width) solid var(--xs-body-cta-button-border);
    border-radius: 10px;
    background-color: var(--xs-body-cta-button-background);
    padding: 15px 28px 17px;
    width: 196px;

    @media (min-width: 768px) and (max-width: 1199px) {
      float: right;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      margin-top: 15px;
      margin-bottom: 15px;
      white-space: nowrap;
    }

    @media (max-width: 767px) {
      margin: 0 auto 4px;
      line-height: 20px;
    }

    &:hover {
      color: var(--xs-body-cta-button-hover-color);
      border-color: var(--xs-body-cta-button-hover-border);
      background-color: var(--xs-body-cta-button-hover-background);
    }
  }
}
