/**
 *
 */

.locator-drawer {
  @media (min-width: 768px) {
    background: var(--sm-locator-drawer-background);
    color: var(--sm-locator-drawer-color);
  }

  .container {
    @media (min-width: 768px) {
      background: var(--sm-locator-drawer-container-background);
    }

    h2 {
      margin: 0 -15px;
      line-height: 24px;
      font-size: 16px;
      padding: 0;

      @media (max-width: 767px) {
        margin: 0;
      }

      @media (max-width: 991px) {
        padding: 0;
      }

      > a.dropdown-toggle {
        display: block;
        font-size: 16px;
        line-height: 24px;
        padding: 13px 9px 13px 20px;

        @media (min-width: 768px) {
          padding: 13px 39px 13px 25px;
        }

        @media (min-width: 992px) {
          padding: 13px 30px;
        }

        .hide {
          display: inline !important;
        }

        .show {
          display: none !important;
        }

        &.collapsed {
          .hide {
            display: none !important;
          }

          .show {
            display: inline !important;
          }
        }

        .caret {
          vertical-align: bottom;

          @media (min-width: 768px) {
            color: var(--sm-links-caret-color);
          }
        }
      }
    }
  }
}

.locator {
  background: var(--xs-locator-background);
  color: var(--xs-locator-color);

  @media (min-width: 768px) {
    background: var(--sm-locator-background);
  }

  .container {
    @media (min-width: 768px) {
      background: var(--sm-locator-container-background);
    }
  }
}

#map {
  & > .row {
    position: relative;
  }

  .locator-initialized {
    display: none;
  }

  .mapboxgl-popup-anchor-bottom {
    top: -12px;
  }

  .mapboxgl-popup-content {
    padding: 10px 22px 10px 12px;
    min-width: 194px;
  }

  .mapboxgl-popup-close-button {
    color: var(--xs-button-background);
    padding: 0;
    margin: 0 7px 0 0;
    font-size: 0;
    line-height: 0;
    background: transparent;

    &:focus {
      outline: none;
    }

    &::before {
      content: "\f057";
      font-family: FontAwesome;
      display: inline-block;
      font-size: 16px;
      line-height: 16px;
    }
  }
}

#map-panel-holder.collapsing {
  overflow: visible;
}

#map-panel-holder {
  padding: 0;

  @media (min-width: 992px) {
    padding: 0;
    width: 500px;
  }

  @media (min-width: 1200px) {
    width: 670px;
  }

  &.collapse {
    @media (min-width: 768px) {
      display: block;
    }
  }

  .mapboxgl-popup-content {
    padding: 10px 12px;
    margin: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    line-height: 14px;
    font-size: 12px;
    color: var(--xs-locator-item-color);

    a {
      color: var(--xs-locator-link-color);
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
    }

    strong.name {
      display: block;
      margin-bottom: 4px;
    }

    dl {
      margin: 8px 0 0;
    }

    dl,
    dt,
    dd {
      line-height: 14px;
    }
  }
}

.mapboxgl-ctrl-geocoder {
  display: none;
}

.mapboxgl-ctrl-geocoder:last-child {
  display:  block;
}

#location-map {
  background-color: var(--xs-mapbox-background);
  height: 240px;

  @media (min-width: 768px) {
    height: 296px;
  }

  @media (min-width: 992px) {
    height: 550px;
  }

  .mapboxgl-ctrl-compass {
    display: none;
  }
}

.mapboxgl-popup-tip {
  width: 20px;
  height: 10px;
  position: relative;
  overflow: hidden;
  border: 0;
}

.mapboxgl-popup-tip:after {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background: #fff;
  transform: rotate(45deg);
  top: -7px;
  left: 3.5px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip:after {
  top: 3.5px;
}

.mapboxgl-popup-content {
  padding: 15px 35px 5px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    span {
      padding-left: 3px;
    }
  }
}

/* BEGIN LICENSE: https://github.com/tobiasahlin/SpinKit/blob/master/LICENSE */

:root {
  --spinner-circles: 12;
  --spinner-duration: 1.2s;
  --spinner-margin: 40px auto;
  --spinner-size: 40px;
}

.sk-circle {
  margin: var(--spinner-margin);
  width: var(--spinner-size);
  height: var(--spinner-size);
  position: relative;

  .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-child::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 18%;
    height: 14%;
    background-color: var(--xs-spinner-color);
    border-radius: 2px;
    transform: scale(0.5, 1.8);
    animation: sk-circleBounceDelay var(--spinner-duration) infinite ease-in-out both;
  }

  .sk-circle2 { transform: rotate(calc(360deg / var(--spinner-circles) * (2 - 1))); }
  .sk-circle3 { transform: rotate(calc(360deg / var(--spinner-circles) * (3 - 1))); }
  .sk-circle4 { transform: rotate(calc(360deg / var(--spinner-circles) * (4 - 1))); }
  .sk-circle5 { transform: rotate(calc(360deg / var(--spinner-circles) * (5 - 1))); }
  .sk-circle6 { transform: rotate(calc(360deg / var(--spinner-circles) * (6 - 1))); }
  .sk-circle7 { transform: rotate(calc(360deg / var(--spinner-circles) * (7 - 1))); }
  .sk-circle8 { transform: rotate(calc(360deg / var(--spinner-circles) * (8 - 1))); }
  .sk-circle9 { transform: rotate(calc(360deg / var(--spinner-circles) * (9 - 1))); }
  .sk-circle10 { transform: rotate(calc(360deg / var(--spinner-circles) * (10 - 1))); }
  .sk-circle11 { transform: rotate(calc(360deg / var(--spinner-circles) * (11 - 1))); }
  .sk-circle12 { transform: rotate(calc(360deg / var(--spinner-circles) * (12 - 1))); }

  .sk-circle2::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (2 - 1)); }
  .sk-circle3::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (3 - 1)); }
  .sk-circle4::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (4 - 1)); }
  .sk-circle5::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (5 - 1)); }
  .sk-circle6::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (6 - 1)); }
  .sk-circle7::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (7 - 1)); }
  .sk-circle8::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (8 - 1)); }
  .sk-circle9::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (9 - 1)); }
  .sk-circle10::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (10 - 1)); }
  .sk-circle11::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (11 - 1)); }
  .sk-circle12::before { animation-delay: calc(-1 * var(--spinner-duration) + var(--spinner-duration) / var(--spinner-circles) * (12 - 1)); }
}

@keyframes sk-circleBounceDelay {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* END LICENSE */
