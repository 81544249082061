#start-geolocate {
  position: relative;
  margin: 0 -15px;

  .locator-initialized {
    display: none;
  }

  .geolocate-loading.loading {
    min-height: 236px;

    @media (min-width: 768px) {
      min-height: 340px;
    }

    @media (min-width: 992px) {
      min-height: 440px;
    }
  }

  .geolocate-error-wrapper {
    background: var(--sm-locator-error-background);
    border: var(--border-width) solid var(--sm-locator-error-border);
    font-weight: bold;
    margin: 20px 20px 0;
    padding: 10px;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 24px;
      margin: 40px 40px 0;
      padding: 19px 28px;
    }

    @media (min-width: 992px) {
      margin: 65px 150px 0;
    }

    @media (min-width: 1200px) {
      margin: 65px 250px 0;
    }
  }

  .geolocate-input {
    padding: 20px;
    min-height: 236px;
    position: relative;
    z-index: 1;

    @media (min-width: 768px) {
      margin: 70px 55px;
      min-height: 0;
      border: var(--border-width) solid var(--sm-locator-border);
      padding: 34px 28px 38px;
      background: var(--sm-locator-container-background);
    }

    @media (min-width: 992px) {
      margin: 120px 165px;
    }

    @media (min-width: 1200px) {
      margin: 120px 265px;
    }
  }

  .active-error + .geolocate-input {
    @media (min-width: 768px) {
      margin: 20px 40px 40px;
    }

    @media (min-width: 992px) {
      margin: 20px 150px 65px;
    }

    @media (min-width: 1200px) {
      margin: 20px 250px 65px;
    }
  }

  &::after {
    content: " ";
    background: var(--xs-locator-start-background) center center;
    background-size: cover;
    opacity: 0.05;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;

    @media (min-width: 768px) {
      top: -70px;
      bottom: -70px;
      background: var(--sm-locator-start-background) center center;
    }

    @media (min-width: 992px) {
      top: -120px;
      bottom: -120px;
    }
  }

  .location-center {
    z-index: 1;
    position: relative;
  }

  .start-label {
    padding: 0 10px;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: var(--xs-locator-color);
    margin-bottom: 15px;

    @media (min-width: 768px) {
      padding: 0 0 26px;
      margin: 0;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .location-holder {
    .btn {
      padding: 14px 13px;
      width: 170px;
      border-radius: 10px;
      margin: 0 0 20px;
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
      background: var(--xs-locator-button-background);
      color: var(--xs-locator-button-color) !important;
      border: var(--border-width) solid var(--xs-locator-button-border);

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      &::before {
        content: "\f124";
        font-family: "FontAwesome";
        font-size: 24px;
        vertical-align: top;
        display: inline-block;
        line-height: 18px;
      }

      &:hover {
        background: var(--xs-locator-button-hover-background);
        border-color: var(--xs-locator-button-hover-border);
        color: var(--xs-locator-button-hover-color) !important;
      }
    }
  }
}

#start-geocoder {
  @media (min-width: 768px) {
    display: inline-block;
    vertical-align: middle;
    border-left: var(--border-width) solid var(--sm-locator-divider);
    padding-left: 34px;
    margin-left: 34px;
  }

  .mapboxgl-ctrl-geocoder {
    box-shadow: none;
    min-width: none;
    max-width: none;
    width: auto;
    background:  transparent;

    > div {
      display: inline-block;
    }
  }

  input {
    background: var(--xs-search-input-background);
    border: 1px solid var(--xs-locator-geocoder-border);
    height: calc(46px + 2 * var(--border-width));
    width: calc(100% - 56px - 2 * var(--border-width));
    padding: 16px 16px 18px;
    display: block;
    font-size: 14px;
    float: left;
    line-height: 16px;

    @media (min-width: 768px) {
      width: 240px;
    }

    &::-ms-clear {
      display: none;
    }
  }

  .suggestions {
    width: calc(100% - 56px - 2 * var(--border-width));
  }

  .geocoder-pin-right {
    position: relative;
    display: inline-block;

    .geocoder-icon-close {
      top: -18px;
      left: -25px;
    }

    .geocoder-icon-loading {
      top: 13px;
      left: -60px;
    }
  }

  .geocoder-pin-far-right .btn-search {
    background: var(--xs-locator-locate-background);
    color: var(--xs-locator-locate-color);
    border: var(--border-width) solid var(--xs-locator-locate-border);
    border-radius: 0 10px 10px 0;
    font-size: 0;
    line-height: 0;
    padding: 10px 16px 12px 17px;
    display: inline-block;

    &:hover {
      background: var(--xs-locator-locate-hover-background);
      border-color: var(--xs-locator-locate-hover-border);
      color: var(--xs-locator-locate-hover-color) !important;
    }

    &::before {
      content: "\f002";
      font-family: "FontAwesome";
      display: inline-block;
      font-size: 24px;
      line-height: 24px;
    }
  }
}

