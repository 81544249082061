.categories {
  background-color: var(--xs-categories-background);

  @media (min-width: 768px) {
    background-color: var(--sm-categories-background);
  }

  .container {
    padding: 30px 30px 25px;

    @media (min-width: 768px) {
      background-color: var(--sm-categories-container-background);
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 30px 15px 25px;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }

    h2 {
      color: var(--xs-categories-heading-color);
      font-weight: 600;
      font-size: 26px;
      line-height: 30px;
      margin: 0 0 10px;

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 5px;
      }
    }

    p {
      color: var(--xs-categories-color);
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;

      @media (min-width: 992px) and (max-width: 1199px) {
        margin-bottom: 20px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
      }

      a {
        color: var(--xs-categories-link-color);

        &:hover {
          color: var(--xs-categories-link-hover-color);
        }
      }
    }

    .categories-grid {
      padding: 0;
      margin-bottom: 0;

      &::before {
        display: none;
      }

      @media (min-width: 768px) {
        padding: 2.5px 7.5px 0;
        display: flex;
        flex-wrap: wrap;
      }

      @media (min-width: 992px) {
        padding: 0 5px;
      }

      @media (min-width: 1200px) {
        padding-top: 5px;
      }

      .category-item {
        background-color: transparent;
        padding: 2.5px 15px;

        @media (min-width: 768px) {
          padding: 7.5px;
          display: flex;
        }

        @media (min-width: 992px) {
          padding: 10px;
        }

        @media (min-width: 1200px) {
          padding: 15px;
        }

        a {
          text-decoration: none;

          @media (min-width: 768px) {
            display: flex;
            flex-direction: column;
          }
        }

        a > img {
          max-width: 100%;
        }

        a > span {
          background-color: var(--xs-categories-item-background);
          border: var(--border-width) solid var(--xs-categories-item-border-color);
          color: var(--xs-categories-item-color);
          font-weight: 600;
          font-size: 21px;
          line-height: 24px;
          text-align: center;
          padding: 21px 20px;
          display: block;

          @media (min-width: 768px) {
            background-color: var(--sm-categories-item-background);
            border-color: var(--sm-categories-item-border);
            padding: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 0 auto;
          }

          @media (min-width: 992px) {
            padding: 17px 14px;
          }

          @media (min-width: 1200px) {
            padding: 21px 18px;
          }

          @media (max-width: 767px) {
            padding: 14px 10px 15px;
            font-size: 18px;
            line-height: 21px;
          }
        }

        a:hover > span {
          @media (min-width: 768px) {
            background-color: var(--sm-category-item-hover-background);
            border-color: var(--sm-category-item-hover-border);
            color: var(--sm-category-item-hover-color);
          }
        }
      }

      &.grid-4 {
        .category-item {
          a > span {
            font-size: 18px;
            padding: 16px 20px;

            @media (min-width: 768px) {
              padding: 6px 13px;
              min-height: 40px;
            }

            @media (min-width: 992px) {
              padding: 11px 14px;
              min-height: 50px;
            }

            @media (min-width: 1200px) {
              padding: 16px 18px;
              min-height: 60px;
            }
          }
        }
      }
    }
  }
}
