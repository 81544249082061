#content {
  &.collapse {
    @media (min-width: 768px) {
      display: block;
    }
  }
}

.heading-more-information {
  margin: 0;

  .more-information {
    padding: 16px 20px;

    .collapsible-title {
      color: var(--xs-content-more-info-color);
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.content {
  background-color: var(--xs-content-background);

  @media (min-width: 768px) {
    background-color: var(--sm-content-background);
  }

  .container {
    padding: 20px;

    @media (min-width: 768px) {
      background-color: var(--sm-content-container-background);
      padding: 30px 15px;
    }

    @media (min-width: 992px) {
      padding: 40px 30px;
    }

    #header-content {
      margin-bottom: 0;

      @media (max-width: 991px) {
        margin-bottom: 30px;
      }
    }

    h1,
    h2 {
      color: var(--xs-content-heading-color);
      font-weight: 600;
      font-size: 26px;
      line-height: 30px;
      margin: 0 0 10px;

      @media (min-width: 768px) and (max-width: 991px) {
        margin-bottom: 11px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 5px;
      }
    }

    p {
      color: var(--xs-content-color);
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 12px;

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 15px;
        line-height: 24px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
      }

      img {
        display: block;
        margin: 10px auto;
        max-width: 100%;
        
        @media (min-width: 768px) {
          float: left;
          margin: 6px 15px 1em 0;
          max-width: 240px;
        }

        @media (min-width: 992px) {
          margin: 6px 30px 1em 0;
          max-width: 280px;
        }

        @media (min-width: 1200px) {
          max-width: 345px;
        }

      }

      a {
        color: var(--xs-content-link-color);

        &:hover {
          color: var(--xs-content-link-hover-color);
        }
      }

      & + h2 {
        margin-top: 50px;

        @media (min-width: 992px) and (max-width: 1199px) {
          margin-top: 40px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin-top: 30px;
        }

        @media (max-width: 767px) {
          margin-top: 20px;
        }
      }
    }

    .img-col {
      img {
        display: block;
      }
    }

    .btn-holder {
      display: block;
      margin-top: 16px;
      margin-bottom: 20px;

      @media (max-width: 767px) {
        margin-top: 10px;
      }

      @media (min-width: 768px) {
        margin-bottom: 30px;
      }

      @media (min-width: 992px) {
        margin-bottom: 40px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 50px;
      }
    }

    .btn-danger {
      color: var(--xs-content-button-color) !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      border: var(--border-width) solid var(--xs-content-button-border);
      border-radius: 10px;
      background-color: var(--xs-content-button-background);
      padding: 14px 22px 14px 23px;
      width: 170px;

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 16px;
        line-height: 18px;
      }

      &:hover {
        color: var(--xs-content-button-hover-color) !important;
        border-color: var(--xs-content-button-hover-border);
        background-color: var(--xs-content-button-hover-background);
      }
    }
  }
}

#content .legal {
  background-color: var(--xs-content-legal-background);
  color: var(--xs-content-legal-color);

  @media (min-width: 768px) {
    background-color: var(--sm-content-legal-background);
  }

  .container {
    padding: 0 20px 20px;

    @media (min-width: 768px) {
      background-color: var(--sm-content-legal-container-background);
      padding: 0 15px 30px;
    }

    @media (min-width: 992px) {
      padding: 0 30px 40px;
    }

    @media (min-width: 1200px) {
      padding: 0 35px 50px;
    }

    .content-legal-text {
      border-top: 1px solid var(--xs-content-legal-border);
      list-style: none;
      padding: 21px 15px 0;
      margin: 0;

      @media (min-width: 992px) and (max-width: 1199px) {
        padding: 23px 10px 0;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        padding: 21px 10px 0;
      }

      a {
        color: var(--xs-content-legal-link-color);

        &:hover {
          color: var(--xs-content-legal-link-hover-color);
        }
      }

      > li {
        font-weight: normal;
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 5px;
        text-indent: -10px;
      }
    }
  }
}
