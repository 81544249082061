.footer {
  .social {
    @media (min-width: 992px) {
      float: right;
      width: 410px;
      text-align: center;
    }
  }

  .navbar-nav {
    float: none !important;

    &.social-text {
      line-height: 15px;
      font-size: 13px;
      color: var(--xs-footer-color);
      margin: 0 -15px 10px;

      @media (min-width: 768px) {
        max-width: 360px;
        margin: 0 auto 10px;
      }
    }

    &.social-icons {
      padding: 0 0 22px;
      margin: 0 -15px;
      text-align: center;

      @media (min-width: 768px) {
        padding: 0 0 31px;
        margin: 0 -15px;
      }

      @media (min-width: 992px) {
        padding: 0;
        margin-bottom: 15px;
      }

      > li a {
        color: var(--xs-footer-color);
        line-height: 30px;
        font-size: 30px;
        padding: 0 10px;

        @media (min-width: 768px) {
          font-size: 40px;
          line-height: 40px;
          padding: 0 15px;
        }
      }
    }
  }
}
