#location-list {
  position: relative;
  padding: 10px;
  background: var(--xs-locator-list-background);
  min-height: 320px;

  @media (min-width: 768px) {
    min-height: 366px;
  }

  @media (min-width: 992px) {
    height: 640px;
    overflow: auto;
    padding: 0;
    background: var(--md-locator-background);
  }

  &.error {
    background: var(--xs-locator-list-error-background);

    @media (min-width: 992px) {
      border: var(--border-width) solid var(--md-locator-list-error-border);
    }
  }

  a {
    color: var(--xs-locator-link-color);

    &:hover {
      color: var(--xs-locator-link-hover-color);
    }
  }

  p.no-locations {
    background: var(--xs-locator-error-background);
    color: var(--xs-locator-error-color);
    border: var(--border-width) solid var(--xs-locator-error-border);
    font-size: 18px;
    padding: 30px;
    text-align: center;
    margin: 30px;

    @media (min-width: 992px) {
      margin: 49px 39px 0;
    }
  }
}

#list-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);

  .sk-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
  }
}

#list-panel {
  padding: 0;
  position: relative;

  @media (min-width: 992px) {
    padding: 0;
    width: 470px;
  }

  @media (min-width: 1200px) {
    width: 500px;
  }

  .list-group {
    @media (min-width: 992px) {
      max-height: 640px;
    }
  }

  .list-group-item {
    border: none;

    &:first-child {
      border-radius: 0;
    }

    &.active:hover {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .place {
    background: var(--xs-locator-item-background);
    border-radius: 0;
    color: var(--xs-locator-item-color);
    font-size: 14px;
    margin: 0 0 10px;
    padding: 10px;
    text-align: center;
    line-height: 18px;
    min-height: 250px;

    @media (min-width: 768px) {
      min-height: 286px;
    }

    @media (min-width: 992px) {
      min-height: 0;
      padding: 35px 24px 25px 30px;
      margin: 0;
      text-align: left;
      border-bottom: 1px solid var(--md-locator-item-border);
      font-size: 13px;
    }

    @media (min-width: 1200px) {
      padding: 30px;
    }

    &.active {
      background: var(--sm-locator-active-background);

      &:hover {
        color: var(--xs-locator-item-color);
      }
    }

    > .row {
      > .col-md-7 {
        @media (min-width: 992px) {
          width: 260px;
        }

        @media (min-width: 1200px) {
          width: 280px;
        }
      }

      > .col-md-5 {
        @media (min-width: 992px) {
          width: 170px;
          text-align: center;
        }
      }
    }

    strong.name {
      display: block;
      font-size: 18px;
      margin: 5px 0 6px;
      line-height: 21px;
      font-weight: 600;

      @media (min-width: 768px) {
        margin-bottom: 5px;
      }

      @media (min-width: 992px) {
        margin-top: 0;
      }

      a {
        font-size: 18px;
      }
    }

    .street {
      a {
        text-decoration: none;
      }
    }

    .telephone a::before {
      @media (min-width: 768px) {
        font-family: FontAwesome;
        content: "\f095";
        display: inline-block;
        text-decoration: none;
        padding-right: 3px;
        vertical-align: bottom;
      }
    }

    .hours {
      line-height: 17px;
      margin: 9px 0 0;
      min-height: 17px;

      @media (min-width: 768px) {
        margin: 10px 0 0;
      }

      @media (min-width: 992px) {
        line-height: 21px;
        min-height: 21px;
      }

      dl,
      dd,
      dt {
        line-height: 17px;
        display: inline;
        margin: 0;

        @media (min-width: 992px) {
          line-height: 21px;
        }
      }

      dt {
        &::after {
          content: ": ";
        }
      }

      dd a {
        color: var(--xs-locator-list-special-hours-color);
        font-weight: 600;
      }
    }

    .links {
      margin-top: 10px;
      font-size: 13px;
      line-height: 15px;

      a {
        display: inline-block;
      }

      a:first-child {
        margin: 0;
        padding: 0;
        border: 0;
      }
    }

    .interact {
      padding-top: 15px;

      @media (min-width: 992px) {
        padding: 15px 0 0;
      }

      @media (min-width: 1200px) {
        padding: 20px 0 0;
      }
    }

    .secondary {
      padding: 10px 0 0;
      font-size: 15px;
      line-height: 20px;

      @media (min-width: 992px) {
        padding: 20px 0 0;
      }

      @media (min-width: 1200px) {
        padding: 19px 0 0;
      }
    }

    .btn {
      padding: 13px 11px 15px;
      width: 170px;
      font-size: 16px;
      line-height: 18px;
      font-weight: bold;
      background: var(--xs-locator-list-button-background);
      border: var(--border-width) solid var(--xs-locator-list-button-border);
      color: var(--xs-locator-list-button-color) !important;
      border-radius: 10px;

      &:hover {
        background: var(--xs-locator-list-button-hover-background);
        border-color: var(--xs-locator-list-button-hover-border);
        color: var(--xs-locator-list-button-hover-color) !important;
      }
    }
  }

  .icons {
    line-height: 12px;
    padding: 15px 0 5px;

    @media (min-width: 768px) {
      padding: 15px 27px 5px;
    }

    .col-xs-3 {
      float: none;
      display: inline-block;
      width: auto;
      padding: 0px 20px;
    }

    .fa {
      margin-bottom: 1px;
    }

    a {
      font-size: 12px;
      text-decoration: none;
      line-height: 12px;
    }

    i {
      display: block;
      font-size: 25px;
    }
  }

  .pager {
    background: var(--xs-locator-more-background);
    border-radius: 5px;
    font-size: 15px;
    margin: 0;

    .col-xs-3,
    .col-xs-6 {
      padding: 0;
    }

    a {
      background: var(--xs-locator-more-button-background);
      color: var(--xs-locator-more-button-color);
      border: var(--border-width) solid var(--xs-locator-more-button-border);
      border-radius: 0;
      font-size: 18px;
      line-height: 18px;
      display: inline-block;

      &.left {
        border-radius: 5px 0 0 5px;
        float: left;
        padding: calc(12px - var(--border-width)) calc(32px - var(--border-width)) calc(9px - var(--border-width)) calc(30px - var(--border-width));

        @media (min-width: 768px) {
          padding: calc(16px - var(--border-width)) calc(32px - var(--border-width)) calc(15px - var(--border-width)) calc(30px - var(--border-width));
        }
      }

      &.right {
        border-radius: 0 5px 5px 0;
        float: right;
        padding: calc(12px - var(--border-width)) calc(30px - var(--border-width)) calc(9px - var(--border-width)) calc(32px - var(--border-width));

        @media (min-width: 768px) {
          padding: calc(16px - var(--border-width)) calc(30px - var(--border-width)) calc(15px - var(--border-width)) calc(32px - var(--border-width));
        }
      }

      &:hover {
        background: var(--xs-locator-more-button-hover-background);
        color: var(--xs-locator-more-button-hover-color);
        border: var(--border-width) solid var(--xs-locator-more-button-hover-border);
      }
    }

    li.col-xs-6 {
      color: var(--xs-locator-more-color);
      line-height: 20px;
      padding: 9px 0 11px;

      @media (min-width: 768px) {
        padding: 15px 27px 15px 28px;
      }
    }
  }
}

.location.map {
  #list-panel {
    .place {
      cursor: default;
    }
  }
}
