.offers-grid {
  background-color: var(--xs-offers-background);

  @media (min-width: 768px) {
    background-color: var(--sm-offers-background);
  }

  .container {
    padding: 40px 30px;

    @media (min-width: 768px) {
      background-color: var(--sm-offers-container-background);
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 30px 15px;
    }

    @media (max-width: 767px) {
      padding: 20px;
    }

    .column {
      @media (min-width: 768px) and (max-width: 991px) {
        margin: 0 90px;
      }
    }

    .panel {
      margin: 0;
      background: var(--xs-offers-grid-item-background);
      border: var(--border-width) solid var(--xs-offers-grid-wrapper-border);
      padding: 20px;
      text-align: center;
      color: var(--xs-offers-color);
      font-family: "Open Sans";
      font-size: 13px;
      font-weight: bold;
      line-height: 16px;

      @media (min-width: 768px) {
        padding: 30px;
        margin: 20px 20px 0;
        width: 100%;
      }
    }

    .offers-grid-wrapper {
      @media (min-width: 768px) {
        &.row {
          display: flex;
          flex-wrap: wrap;

          &::before, &::after {
            display: none;
          }

          & > div[class*='col-'] {
            display: flex;
            float: none;

            &::before, &::after {
              display:none;
            }
          }

          & > div:nth-child(n + 5) {
            @media (min-width: 768px) {
              display: none;
            }
          }

          &.in > div:nth-child(n + 5) {
            @media (min-width: 768px) {
              display: flex;
            }
          }
        }
      }

      @media (min-width: 768px) {
        &.row > div[class*='col-']:nth-child(odd) {
          clear: both;
        }
      }

      @media (max-width: 767px) {
        margin: 0;

        & > div[class*="col-"] {
          padding: 0;

          &:last-child {
            .offers-grid-item::after {
              border-bottom: 0 none var(--xs-offers-grid-separator-border);
            }
          }
        }
      }
    }

    h2 {
      color: var(--xs-offers-title-color);
      font-weight: 600;
      font-size: 26px;
      line-height: 30px;
      margin: 0 0 10px;

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 6px;
      }
    }

    p {
      color: var(--xs-offers-color);
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;

      @media (min-width: 768px) {
        margin-bottom: 15px;
      }

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
      }

      a {
        color: var(--xs-offers-link-color);

        &:hover {
          color: var(--xs-offers-link-hover-color);
        }
      }
    }

    .offers-grid-wrapper {
      > *:nth-child(n + 4) {
        display: none;
      }

      &.in > *:nth-child(n + 4) {
        display: block;
      }
    }

    .offers-grid-item {
      background-color: var(--xs-offers-grid-item-background);
      border: var(--border-width) solid var(--sm-offers-grid-item-border);
      padding: 8px;
      margin: 15px 0;
      min-height: 200px;

      @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 15px 0 5px;

        & > .row {
          display: flex;
          flex-direction: row;
          width: calc(100% + 30px);
        }
      }

      @media (min-width: 1200px) {
        margin: 15px 0;
      }

      @media (max-width: 767px) {
        min-height: unset;
        padding: 8px 8px 20px;
        margin: 0 0 10px;
      }

      img {
        max-width: 180px;
        max-height: 360px;

        @media (min-width: 768px) and (max-width: 991px) {
          min-width: 180px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
          min-width: 180px;
        }

        @media (max-width: 767px) {
          margin-top: 10px;
          max-height: 150px;
        }
      }

      .offer-img {
        @media (min-width: 768px) {
          float: none;
          display: flex;
          flex-direction: column;
          width: 215px;
        }

        @media (max-width: 767px) {
          text-align: center;

          img {
            max-height: 150px;
          }
        }
      }

      .offer-info {
        min-height: 180px;
        margin-left: 0;
        padding-left: 6px;

        @media (min-width: 768px) {
          float: none;
          display: flex;
          flex-direction: column;
        }

        @media (min-width: 768px) {
          padding: 0 10px 0 0;
        }

        @media (min-width: 992px) {
          padding: 5px 0 0 0;
        }

        @media (min-width: 1200px) {
          padding: 10px 0 0 0;
        }

        @media (max-width: 767px) {
          text-align: center;
          min-height: auto;
          padding-left: 15px;
        }

        .offer-title {
          color: var(--xs-offer-title-color);
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          margin-top: 6px;
          margin-bottom: 4px;
          display: inline-block;

          @media (min-width: 992px) {
            font-size: 16px;
            line-height: 19px;
            margin: 0;
            padding: 0;
          }

          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 21px;
            margin: 10px 0 0;
          }
        }

        .offer-text {
          color: var(--xs-offer-color);
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          margin-bottom: 18px;
          padding: 3px 0 5px;

          @media (min-width: 768px) {
            margin-bottom: 0;
          }

          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 12px;
            line-height: 15px;
          }

          @media (max-width: 767px) {
            font-size: 13px;
            line-height: 16px;
            margin: 0;
          }
        }

        a,        
        a .offer-text {
          text-decoration: none;
        }

        a .offer-title {
          text-decoration: underline;
        }

        > .row {
          @media (min-width: 768px) {
            flex-grow: 1;
            display: flex;
          }
        }

        .price {
          @media (min-width: 768px) {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
          }

          @media (min-width: 992px) {
            padding-top: 5px;
          }
        }

        .cta {
          @media (min-width: 768px) {
            flex: 0 1 auto;
            align-self: flex-end;
            padding-bottom: 10px;
          }

          @media (min-width: 992px) {
            padding-bottom: 5px;
          }
        }

        .offer-price {
          color: var(--xs-offer-price-color);
          font-weight: 600;
          font-size: 21px;
          line-height: 24px;
          margin-top: 1px;

          @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 18px;
            line-height: 21px;
          }

          @media (max-width: 767px) {
            font-size: 21px;
            line-height: 24px;
          }

          a {
            color: var(--xs-offer-price-color);
          }
        }

        .offer-cta-button {
          color: var(--xs-offer-cta-button-color) !important;
          font-weight: 600;
          font-size: 16px;
          line-height: 18px;
          border: var(--border-width) solid var(--xs-offer-cta-button-border);
          border-radius: 10px;
          background-color: var(--xs-offer-cta-button-background);
          padding: 14px 15px;
          width: 170px;
          margin-left: -7px;

          @media (min-width: 992px) and (max-width: 1199px) {
            width: 130px;
          }

          @media (min-width: 768px) and (max-width: 991px) {
            width: 150px;
            float: right;
            clear: both;
          }

          @media (max-width: 767px) {
            float: none;
            clear: both;
            margin-top: 10px;
          }

          @media (min-width: 1200px) {
            margin-left: 0;
          }

          &:hover {
            color: var(--xs-offer-cta-button-hover-color) !important;
            border-color: var(--xs-offer-cta-button-hover-border);
            background-color: var(--xs-offer-cta-button-hover-background);
          }
        }

        .cta > .row {
          @media (max-width: 767px) {
            padding-top: 10px;
            display: flex;
          }
        }

        .offer-secondary-action {
          color: var(--xs-offer-secondary-action-link-color);
          text-decoration: underline;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          display: block;
          margin: 8px 0 0;
          text-align: center;
          width: 150px;
          margin-left: -7px;

          @media (min-width: 992px) and (max-width: 1199px) {
            line-height: 15px;
            width: 130px;
          }

          @media (min-width: 768px) and (max-width: 991px) {
            width: 150px;
            float: right;
            clear: both;
          }

          @media (max-width: 767px) {
            width: auto;
            text-align: right;
            margin-top: 0;
            line-height: 15px;
          }

          @media (min-width: 1200px) {
            margin-left: 0;
          }

          &:hover {
            color: var(--xs-offers-link-hover-color);
          }
        }

        .offer-cta-button,
        .offer-secondary-action {
          @media (min-width: 1200px) {
            float: right;
            clear: both;
            margin-right: 10px;
          }
        }

        .condition {
          @media (min-width: 768px) {
            flex-grow: 1;
            display: flex;
          }
        }

        .offer-expires {          
          color: var(--xs-offer-expires-color);
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          flex: 0 1 auto;
          align-self: flex-end;

          &.col-xs-6 {
            text-align: left;
          }

          @media (min-width: 768px) {
            padding-bottom: 10px;
          }

          @media (min-width: 992px) {
            padding-bottom: 5px;
          }
        }

        &.no-image {
          padding: 0 15px;

          @media (min-width: 768px) {
            padding: 5px 25px;
          }

          .offer-cta-button,
          .offer-secondary-action {
            float: right;
            clear: both;
            margin-right: 0;
          }

          @media (max-width: 767px) {
            .offer-cta-button {
              float: none;
              clear: both;
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }

      &.image-only {
        border: 0;
        padding: 0;
        background-color: var(--xs-offers-background);

        img {
          width: 100%;
          max-height: none;
          max-width: none;
          margin: 0;
        }

        @media (max-width: 767px) {
          background-color: var(--xs-offers-background);

          &::after {
            margin-top: 0;
            border: 0;
          }
        }
      }
    }

    .offer-more {
      background-color: var(--xs-offer-more-background);
      text-align: center;
      border: var(--border-width) solid var(--xs-offers-grid-wrapper-border);

      @media (min-width: 768px) {
        margin-top: 15px;
      }

      .offer-view-more {
        padding: 20px;
        display: block;
        color: var(--xs-offer-view-more-color);
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
        text-transform: uppercase;
        text-decoration: none;

        span,
        img {
          display: block;
          margin: 0 0 5px;
        }

        i.fa {
          font-family: FontAwesome;
          font-size: 24px;
          line-height: 15px;
          text-align: center;
        }
      }
    }
  }
}
