/**
 *
 */

.navbar-collapse {
  background: var(--xs-menu-background);
  border: 0;
  border-radius: 0;
  padding: 40px 0 0;
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);

  @media (min-width: 768px) {
    padding: 0;
    box-shadow: none;
    width: 100%;
    background: transparent;
  }

  @media (min-width: 992px) {
    margin-bottom: 0;
    background: var(--md-menu-background);
    position: static;
  }

  &.collapse {
    @media (max-width: 991px) {
      display: none !important;
    }

    &.in {
      @media (max-width: 991px) {
        display: block !important;
      }
    }
  }

  .navbar-toggle {
    display: block;
  }

  .container {
    padding: 0;

    @media (min-width: 768px) {
      position: relative;
    }

    @media (min-width: 992px) {
      background: var(--md-menu-container-background);
      border: var(--border-width) solid var(--md-menu-container-border);
    }

    a.navbar-brand {
      background: var(--brand-1);
      background-size: contain;
      background-repeat: no-repeat;
      height: var(--md-menu-brand-height);
      min-height: var(--md-menu-brand-height);
      width: var(--md-menu-brand-width);
      margin: 15px 38px;
      padding: 0;
      border: 0;
      text-indent: -9999px;

      &:hover,
      &:focus,
      &:hover:focus {
        background-color: transparent;
        border: none;
      }
    }

    .menu-brand a.navbar-brand {
      min-height: 0;
    }

    .menu-brand a.navbar-brand:focus {
      outline: none;
      outline-offset: 0;
    }
  }

  .menus {
    @media (min-width: 768px) and (max-width: 991px) {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      padding: 40px 0 0;
      margin: 0;
      background: var(--xs-menu-background);
      box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.3);
      width: 320px;
    }
  }

  .pull-right {
    float: none !important;

    @media (min-width: 992px) {
      float: right !important;
    }
  }

  ul.navbar-nav {
    margin: 0 auto;
    float: none;

    > li {
      float: none;

      @media (min-width: 992px) {
        float: left;
      }
    }

    .caret {
      @media (min-width: 992px) {
        display: none;
      }

      &::before {
        content: "\f078";
        display: inline-block;
        float: right;
        position: relative;
        left: 42px;
        font-size: 18px;
        line-height: 18px;
      }
    }

    .open {
      background: var(--xs-menu-item-background);

      .caret::before {
        content: "\f077";
      }

      .dropdown-menu {
        text-align: center;
        padding: 0;

        @media (max-width: 991px) {
          position: static;
          float: none;
          width: auto;
          margin-top: 0;
          background-color: transparent;
          border: 0;
          box-shadow: none;
        }

        .divider {
          background: var(--xs-menu-background);
          margin: 0;
          height: 9px;
        }
      }
    }

    & > li.hidden-md,
    & > li.divider {
      > a {
        padding: 0;
        border: 0;
      }
    }

    .open > a,
    .open .dropdown-menu > li > a,
    & > li > a {
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
      border: var(--border-width) solid var(--xs-menu-item-border);
      color: var(--xs-menu-item-color);
      text-decoration: none;
      background-color: var(--xs-menu-item-background);

      @media (min-width: 992px) {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        border-right-width: 0;
      }

      @media (min-width: 1200px) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    .open > a,
    .open > a:focus,
    .open .dropdown-menu > li > a,
    & > li > a,
    & > li:not(.menu-brand) > a:focus {
      padding: 14px 58px 14px 18px;

      @media (min-width: 992px) {
        padding: 25px 28px 23px;
      }

      @media (min-width: 1200px) {
        padding-top: 24px;
      }

      &:hover {
        background-color: var(--xs-menu-hover-background);
        border-color: var(--xs-menu-hover-border);
        color: var(--xs-menu-hover-color);
      }
    }

    .open .dropdown-menu > li > a:hover {
      background: var(--xs-submenu-hover-background);
      border-color: var(--xs-submenu-hover-color);
      color: var(--xs-submenu-hover-color);
    }

    & > li:last-child > a,
    .open .dropdown-menu > li > a {
      @media (min-width: 992px) {
        border-right-width: var(--border-width);
      }
    }

    .open .dropdown-menu > li > a {
      background: var(--xs-submenu-item-background);
      border-color: var(--xs-submenu-item-border);
      text-align: left;
      padding-left: 38px;

      @media (min-width: 992px) {
        font-size: 16px;
        font-weight: bold;
        line-height: 18px;
        padding: 14px 58px 14px 18px;
      }
    }

    .dropdown-menu {
      @media (min-width: 768px) and (max-width: 991px) {
        width: 100%;
      }

      @media (min-width: 992px) {
        width: 320px;
      }
    }

    li {
      position: static;

      > .navbar-toggle {
        float: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
      }

      > .text-center {
        height: 40px;
        width: calc(100% - 96px);
        border-top: var(--border-width) solid var(--xs-menu-border);
        border-bottom: var(--border-width) solid var(--xs-menu-border);
        position: absolute;
        top: 0;
        left: 48px;
      }
    }

    li.dropdown {
      @media (min-width: 992px) {
        position: relative;
      }
    }

    li.close {
      text-align: center;
      display: block;
      float: none;
      line-height: initial;
      opacity: 1;
      position: absolute;
      padding: 0 !important;
      right: 0;
      top: 0;
      border: var(--border-width) solid white;

      @media (min-width: 768px) and (max-width: 991px) {
        z-index: 4;
      }

      > a,
      > a:focus {
        background: var(--xs-menu-close-background);
        border: 0;
        color: var(--xs-menu-close-color);
        width: 44px;
        line-height: 24px;
        text-shadow: none;
        font-size: 24px;
        padding: 6px 0;

        @media (min-width: 768px) {

        }
      }

      > a:hover {
        background: var(--xs-menu-hover-background);
        color: var(--xs-menu-hover-color);
      }

      &:hover {
        border-color: var(--xs-menu-hover-border);
      }
    }

    &.links {
      & > li > a {
        background: var(--xs-menu-top-background);
        border-color: var(--xs-menu-top-border);

        &:hover {
          background: var(--xs-menu-hover-background);
          border-color: var(--xs-menu-hover-border);
          color: var(--xs-menu-hover-color);
        }
      }
    }

    &.utility {
      & > li > a {
        background: var(--xs-menu-utility-background);
        border-color: var(--xs-menu-utility-border);

        &:hover {
          background: var(--xs-menu-hover-background);
          border-color: var(--xs-menu-hover-border);
          color: var(--xs-menu-hover-color);
        }
      }
    }
  }

  .navbar-text {
    font-size: 14px;
    line-height: 18px;
    padding: 28px 38px;
    margin: 0;
    float: none !important;
    border: var(--border-width) solid var(--xs-menu-top-text-border);
    background: var(--xs-menu-top-text-background);
    color: var(--xs-menu-top-text-color);
    border-top: 0;
    text-align: center;

    a {
      color: var(--xs-menu-top-text-color);
    }
  }
}
