.related {
  padding-top: 10px;

  .list-group-item {
    border: 0;
    padding: 0 20px;

    a {
      font-size: 14px;
      color: var(--xs-links-color);
      text-decoration: underline;
      line-height: 32px;
    }
  }
}

.hasPOS,
.availableFrom,
.areaServed,
.makesOffer,
.containsPlace {
  background: var(--xs-links-background);
  color: var(--xs-links-color);

  @media (min-width: 768px) {
    background: var(--sm-links-background);
  }

  .container {
    @media (min-width: 768px) {
      background: var(--sm-links-container-background);
      padding-bottom: 14px;
    }

    @media (min-width: 992px) {
      padding: 0 30px 20px;
    }

    @media (min-width: 992px) {
      padding: 0 30px 30px;
    }
  }

  h2 {
    color: var(--sm-links-color);
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    margin: 0 0 17px;

    @media (min-width: 768px) {
      color: var(--text);
      padding-top: 31px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    @media (min-width: 992px) {
      padding-top: 41px;
    }

    @media (min-width: 1200px) {
      padding-top: 51px;
    }

    > a {
      color: var(--text);

      @media (max-width: 767px) {
        padding: 16px 20px;
      }
    }
  }

  .related {
    padding-left: 26px;

    @media (min-width: 992px) {
      padding-left: 2px;
    }

    @media (min-width: 1200px) {
      padding-left: 12px;
    }
  }

  .list-group {
    padding: 12px 5px 29px;
    margin: 0;
    column-count: 2;

    @media (min-width: 768px) {
      padding: 0 15px 17px;
      column-count: 3;
    }

    @media (min-width: 992px) {
      column-count: 4;
    }
  }

  .list-group-item {
    background: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    float: none;

    a {
      color: var(--xs-links-link-color);
      font-weight: normal;
      font-size: 16px;
      line-height: 32px;
      text-decoration: underline;
    }
  }
}

.hasPOS,
.availableFrom {
  .show-more {
    margin: 20px 0;
    padding: 0 5px;

    @media (min-width: 768px) {
      margin-top: 0px;
      padding: 0px;
    }

    @media (min-width: 992px) {
      margin-top: 10px;
    }
  }

  .row-eq-height {
    @media (min-width: 768px) {
      padding-top: 3px;
    }

    &::after, &::before {
      display: none;
    }
  }

  .links-locations-show-more {
    display: block;
    padding: 20px;
    background-color: var(--xs-links-locations-more-background);
    text-align: center;
    border: var(--border-width) solid var(--xs-links-locations-more-border);
    font-size: 16px;
    font-weight: bold;
    line-height: 15px;
    text-align: center;
    color: var(--xs-links-locations-more-color);
    font-weight: 600;

    @media (min-width: 768px) {
      font-size: 18px;
    }

    span,
    img {
      display: block;
      width: auto;
      height: auto;
      margin: 0 0 5px;
    }

    &:hover {
      color: var(--xs-links-locations-more-hover-color);
      background-color: var(--xs-links-locations-more-hover-background);
    }

    i.fa {
      font-family: FontAwesome;
      font-size: 24px;
      line-height: 15px;
      text-align: center;
    }
  }

  .location-data-wrapper {
    margin-bottom: 10px;
    flex: 1 0 auto;
  }

  .location-nearby {
    font-family: "Open Sans";
    padding: 20px 20px 10px 20px;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      padding: 10px 15px 30px;
    }
    
    &::after, &::before {
      display: none;
    }

    .location-nearby-name {
      color: var(--xs-location-link-color);
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: inline-block;
      margin-bottom: 5px;
    }

    .location-nearby-address,
    .location-nearby-directions {
      font-size: 13px;
      line-height: 17px;
    }

    .location-nearby-directions {
      color: var(--xs-location-link-color);
    }

    .location-nearby-phone-number {
      color: var(--xs-location-link-color);
      font-weight: 600;
      font-size: 16px;
      line-height: 17px;
      display: block;
      margin: 10px 0 0;
      text-decoration: none;

      span {
        text-decoration: underline;
      }

      strong {
        text-decoration: none;

        &::before {
          font-family: "FontAwesome";
          content: "\f095";
        }
      }
    }

    a:hover {
      color: var(--xs-location-link-hover-color);
    }

    .location-nearby-hours {
      margin-top: 10px;

      strong {
        display: block;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
      }

      dl {
        margin: 0;
      }

      dt,
      dd {
        display: inline;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
      }
      
      dt:before {
        content: " ";
        display: block;
      }
    }

    .btn {
      border-radius: 10px;
      line-height: 18px;
      font-size: 16px;
      width: 150px;
      padding: 14px 12px;
      color: var(--xs-links-button-color) !important;
      background-color: var(--xs-links-button-background);
      border-color: var(--xs-links-button-border);

      &:hover {
        color: var(--xs-links-button-hover-color) !important;
        background-color: var(--xs-links-button-hover-background);
        border-color: var(--xs-links-button-hover-border);
      }
    }
  }
}

#nearby-locations {
  .location-nearby {
    @media (max-width: 767px) {
      min-width: 240px;
    }
  }

  > *:nth-child(n + 5) {
    display: none;

    @media (min-width: 992px) and (max-width: 1199px) {
      display: flex;

      &::after, &::before {
        display: none;
      }
    }
  }

  > *:nth-child(n + 7) {
    @media (min-width: 992px) and (max-width: 1199px) {
      display: none;
    }
  }

  &.in {
    > *:nth-child(n + 5) {
      display: block;

      @media (min-width: 992px) and (max-width: 1199px) {
        display: flex;
      }
    }

    > *:nth-child(n + 7) {
      @media (min-width: 992px) and (max-width: 1199px) {
        display: block
      }
    }
  }

  @media (min-width: 768px) {
    padding: 0 20px;
  }
}
