.footer {
  ol.breadcrumb {
    background: none;
    line-height: 17px;
    border-radius: 0;
    padding: 0;
    margin: 0 0 18px;
    display: inline-block;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 30px;
    }

    @media (min-width: 992px) {
      margin: 0 0 20px;
    }

    @media (min-width: 1200px) {
      margin: 0 0 22px;
    }

    > li {
      display: inline;

      &::after {
        content: " ";
        letter-spacing: 1em;
        background-image: linear-gradient(-70deg, transparent calc(0.5em - 1px), var(--xs-footer-color), transparent calc(0.5em + 1px));
        background-size: 10px 11px;
        background-repeat: no-repeat;
        background-position: center center;
      }

      & + li::before {
        content: none;
        padding: 0;
      }

      a, span {
        white-space: nowrap;
      }
    }
  }
}
