/**
 *
 */

.header-sm {
  background-color: var(--sm-header-background);
  color: var(--xs-header-color);
  text-align: center;

  .container {
    background-color: var(--xs-header-background);
  }

  h1 {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    padding: 10px 5px;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 26px;
      line-height: 28px;
      padding: 21px 15px;
    }
  }
}

.header {
  @media (min-width: 768px) {
    background-color: var(--sm-cta-background);
  }

  @media (min-width: 992px) {
    background-color: var(--md-header-background);
    color: var(--md-header-color);
  }

  .container {
    @media (min-width: 768px) {
      background-color: var(--sm-header-container-background);
    }

    @media (min-width: 992px) {
      background-color: var(--md-header-container-background);
      padding: 0;

      & > .row {
        display: table;
        width: 100%;
        margin: 0;
      }
    }
  }

  .col-md-8 {
    padding: 50px 30px 30px;
    width: 620px;

    @media (min-width: 1200px) {
      width: 780px;
    }

    h1 {
      font-size: 30px;
      line-height: 33px;
      margin: 0;
      font-weight: bold;

      @media (min-width: 1200px) {
        font-size: 36px;
        line-height: 39px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin: 10px 0 0;
      padding: 0;

      a {
        color: var(--xs-header-link-color);

        &:hover {
          color: var(--xs-header-link-hover-color);
        }
      }
    }
  }
}
