.footer {
  &.columnar {
    ol.breadcrumb {
      @media (min-width: 992px) {
        margin: 20px 0 40px;
      }
    }

    .navbar-text.legal {
      @media (min-width: 992px) {
        width: 700px;
      }
    }

    .disclaimers {
      @media (min-width: 992px) {
        padding-top: 19px;
      }
    }

    .container {
      .navbar-brand {
        @media (min-width: 992px) {
          margin-top: 15px;
        }
      }
    }
  }

  .columns {
    text-align: left;
    padding: 19px 35px 0;
    border-bottom: 1px solid var(--xs-footer-rule-color);
    color: var(--xs-footer-color);
    font-size: 12px;
    line-height: 12px;

    @media (min-width: 768px) {
      padding: 21px 20px 0;
    }

    @media (min-width: 992px) {
      border-top: 1px solid var(--xs-footer-rule-color);
      padding: 19px 0 0;
    }

    @media (min-width: 1200px) {
      padding-top: 20px;
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }

    .navbar-nav {
      @media (max-width: 767px) {

      }

      @media (min-width: 768px) {
        margin: 0 -15px;
      }

      > li {
        padding: 0;

        @media (min-width: 768px) {
          padding: 0 15px;
          display: block;
          float: left;
        }

        &.col-xs-12 {
          @media (min-width: 480px) and (max-width: 767px) {
            display: block;
            float: left;
            width: 50%;
          }

          &.visible-xs {
            @media (min-width: 480px) and (max-width: 767px) {
              width: 100%;
            }
          }
        }
      }

      strong {
        margin-bottom: 5px;
        display: block;
      }

      ul {
        padding: 0 0 20px;
      }

      > li li {
        padding-bottom: 3px;
        list-style: none;
      }

      > li a {
        color: var(--xs-footer-color);
        text-decoration: none;
        line-height: 15px;
      }
    }
  }
}
