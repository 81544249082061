.category-hero {
  background: var(--xs-category-hero-background);
  text-align: center;
  color: var(--xs-category-hero-color);

  @media (min-width: 768px) {
    text-align: left;
    background: var(--sm-category-hero-background);
  }

  .container {
    padding: 20px;

    @media (min-width: 768px) {
      background: var(--sm-category-hero-container-background);
      padding: 30px 15px;
    }

    @media (min-width: 992px) {
      padding: 40px 30px;
    }

    h3 {
      font-size: 24px;
      line-height: 27px;
      font-weight: bold;
      padding: 0;
      margin: 15px 0 0;
      color: var(--xs-category-hero-heading-color);

      @media (min-width: 768px) {
        margin: 0 17px 20px 0;
        font-size: 28px;
        line-height: 30px;
        display: inline-block;
      }
    }

    .location {
      margin: 10px 0 6px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;

      @media (min-width: 768px) {
        margin: 12px 0 20px;
        font-size: 18px;
        line-height: 20px;
        display: inline-block;
      }

      &::before {
        content: "\f041";
        display: inline;
        font-family: FontAwesome;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      .btn {
        text-transform: none;
        font-size: 16px;
        line-height: 18px;
        width: 280px;
        padding: 11px 8px;
        margin-top: 5px;

        @media (min-width: 768px) and (max-width: 991px) {
          padding: 7px 8px;
          width: 220px;
          margin: 0 0 5px;
          line-height: 16px;
        }

        @media (min-width: 992px) {
          margin: 0 0 5px;
        }

        @media (min-width: 1200px) {
          width: 280px;
          padding: 19px 8px;
        }
      }

      li:last-child .btn {
        @media (min-width: 768px) {
          margin: 0;
        }
      }
    }
  }

  .col-sm-4 {
    @media (min-width: 768px) and (max-width: 991px) {
      width: 270px;
    }
  }

  .col-sm-8 {
    @media (min-width: 768px) and (max-width: 991px) {
      width: 480px;
    }
  }

  .button {
    @media (min-width: 992px) {
      width: 325px;
      padding-right: 30px;
    }
  }

  .image {
    @media (min-width: 768px) {
      margin-left: -15px;
      padding: 0;
    }

    @media (min-width: 992px) {
      width: 600px;
      margin-left: 0;
    }

    @media (min-width: 1200px) {
      width: 800px;
    }
  }

  img {
    max-width: 100%;
  }
}
