
.team {
	background-color: var(--xs-team-member-background);
	color: var( --xs-team-member-color);

	.container {
		background-color: var(--xs-team-member-background);
   	padding: 30px;
   	padding-top: 20px;

   	@media (max-width: 644px) {
   		padding: 20px;
   	}
   	
	 	.team-title {
	 		font-family: "Roboto Slab";	
	  	font-size: 18px;	
	  	line-height: 21px;

	  	@media (min-width: 768px) {
	  		font-size: 26px;	
	  		line-height: 30px;
	  	}	
	  	margin-bottom: 6px;
	  }

	  .team-text {
  		font-family: "Open Sans";	
  		font-size: 14px;	
  		line-height: 21px;
  		
  		@media (min-width: 768px) {
  			font-size: 16px;	
  			line-height: 26px;
  		}

	  }

	  .team-grid-container {
	  	text-align: center;
	  	margin-top: 0px;
	  	@media (min-width: 1200px) {
  			margin-top: -10px;
  		}
   	}

 		.team-grid {
 			display: flex;
		  flex-wrap: wrap;
		  align-content: space-around;
		  justify-content: space-between;
		  margin: 0px;

			.team-item {
		  	background-color: var(--xs-team-member-item-background) ;
		    border: var(--border-width) solid var(--xs-team-member-item-border-color);
		  	text-align: center;
		  	align-items: center;
		    display: flex;
		    flex-direction: column;
		    padding: 25px;
		    margin-top: 10px;

		    @media (max-width: 644px) {
		    	width: 100%;
		    	min-width: 240px;
		    }

		    @media (min-width: 645px) {
		      width: 49%;
		      margin-top: 12px;
		    }

		    @media (min-width: 992px) {
		  		width: 33%;
		  		max-width: 290px;
		  		margin-top: 20px;
		    }

		    @media (min-width: 1200px) {
		     	width: 33%;
		     	max-width: 350px;
		     	margin-top: 30px;
		    }
		    
		    .team-name {
		    	color: var( --xs-team-member-color); 
		    	font-family: "Open Sans";	
		    	font-size: 18px;	
		    	font-weight: 600;	
		    	line-height: 24px;	
		    	text-align: center;
		    	margin-top: 10px;
		    }

		    .team-job-title {
		    	color: var( --xs-team-member-color);
		    	font-family: "Open Sans";	
		    	font-size: 15px;	
		    	line-height: 17px;	
		    	text-align: center;
		    	margin-top: 5px;
		    }

		    .team-work-location {
		    	color: var( --xs-team-member-color);
	    		font-family: "Open Sans";	
	    		font-size: 13px;	
	    		line-height: 17px;	
	    		text-align: center;
	    		margin-top: 5px;
		    }

		    .team-description {
		    	color: var( --xs-team-member-color);
	    		font-family: "Open Sans";	
	    		font-size: 14px;	
	    		line-height: 17px;	
	    		text-align: center;
	    		margin-top: 10px;
		    }

		    .team-telephone {
		      margin-top: 10px;
		      text-decoration: underline;
		      a {
		        text-decoration: underline;
		      }
		      a::before {
		        content: "\f095";
		        font-family: FontAwesome;
		        display: inline;
		        color: var(--xs-team-member-link-color);
		        padding-right: 1px;
		      }
		    }

		    .team-picture {

		    	object-fit: cover;
				 	width: 100%;
					height: 160px;

				  @media (min-width: 920px) {
					  width: 100%;
					  height: 160px;
					}
				  @media (min-width: 1200px) {
					  width: 100%;
					  height: 200px;
					}

				}
		  }

		  	> *:nth-child(n + 3) {
			    @media (max-width: 644px) {
			      display: none;
			    }
			  }

			  > *:nth-child(n + 4) {
			    @media (min-width: 992px) {
			      display: none;
			    }
			  }

			  > *:nth-child(n + 5) {
			    @media (min-width: 645px) {
			      display: none;
			    }
			  }

		}

		.team-grid::after {
			content: " ";
			flex: auto;
			@media (max-width: 991px) {
	    	flex: 0 0 50%;
	    }

	    @media (min-width: 992px) {
	    	flex: 0 0 32%;
	    }

	    @media (min-width: 1200px) {
	    	flex: 0 0 31.5%;
	    }
		}
		
		.team-more {
      background-color: var(--xs-team-member-background);
      margin-top: 15px;
      padding: 0px;
      width: 100%;
      min-width:240px;
     	
      .team-view-more {
      	min-width:100%;
        padding: 20px;
        display: block;
        color: var(--xs-team-member-view-more-color);
        border: var(--border-width) solid var(--xs-team-member-grid-wrapper-border);
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
        text-transform: uppercase;
        text-decoration: none;

        span,
        img {
          display: block;
          margin: 0 0 5px;
        }

        i.fa {
          font-family: FontAwesome;
          font-size: 24px;
          line-height: 15px;
          text-align: center;
        }
      }
    }

  }  
}
