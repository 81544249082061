div.not-found {
  background: var(--xs-404-background);
  text-align: center;
  padding: 20px 5px 23px;
  font-size: 16px;
  line-height: 21px;
  color: var(--xs-404-color);

  @media (min-width: 768px) {
    padding: 0;
    background: var(--sm-404-background);
  }

  .container {
    @media (min-width: 768px) {
      background: var(--sm-404-container-background);
      padding: 60px 15px 66px;
    }
  }

  h1 {
    margin: 0;
    padding: 0 0 5px;
    line-height: 30px;
    font-size: 26px;
    font-weight: 600;
    color: var(--xs-404-heading-color);

    @media (min-width: 768px) {
      padding: 0 0 2px;
    }
  }

  p {
    margin: 15px 0 0;

    @media (min-width: 768px) {
      margin: 20px 0 0;
    }
  }
}
