.events {
  background-color: var(--xs-events-background);
  color: var(--xs-events-color);

  @media (min-width: 768px) {
    background-color: var(--sm-events-background);
  }

  .container {
    padding: 20px;

    @media (min-width: 768px) {
      background-color: var(--sm-events-container-background);
      padding: 30px 15px;
    }

    @media (min-width: 992px) {
      padding: 40px 30px;
    }

    > h2 {
      color: var(--xs-events-heading-color);
      font-size: 18px;
      font-weight: 600;
      line-height: 21px;
      padding-bottom: 6px;

      @media (min-width: 768px) {
        font-size: 26px;
        line-height: 30px;
        margin: 0;
        padding-bottom: 10px;
      }
    }
    
    > p {
      font-size: 14px;
      line-height: 21px;
      margin: 0 0 15px;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 26px;
        margin: 0 0 20px;
      }
    }
  }
  
  a {
    color: var(--xs-events-link-color);

    &:hover {
      color: var(--xs-events-link-hover-color);
    }
  }

  .btn {
    line-height: 18px;
    width: 170px;
    padding: 14px 12px;
    color: var(--xs-location-button-color) !important;
    background-color: var(--xs-location-button-background);
    border-color: var(--xs-location-button-border);
    margin: 10px 0 0;
    border-radius: 10px;

    &:hover {
      color: var(--xs-location-button-hover-color) !important;
      background-color: var(--xs-location-button-hover-background);
      border-color: var(--xs-location-button-hover-border);
    }

    @media (min-width: 768px) {
      float: right;
      margin: 0;
    } 
  }

  .column {
    margin: 0 auto;

    @media (min-width: 768px) {
      width: 720px;
    }

    @media (min-width: 992px) {
      width: 910px;
    }
  }
}

#events {
  .event {
    background: var(--xs-events-event-background);
    border: var(--border-width) solid var(--xs-events-event-border);
    color: var(--xs-events-event-color);
    margin: 5px 0;
    padding: 15px 20px 20px;

    @media (min-width: 768px) {
      display: flex;
      flex-direction: row;
      margin: 10px 0;
      padding: 10px;
      width: 100%;

      & > .row {
        display: flex;
        flex-direction: row;
        margin: 0;
        width: 100%;
      }
    }

    img {
      max-width: 100%;
    }    
    
    .locality {
      @media (min-width: 768px) {
        padding-bottom: 10px;
        display: flex;
        flex-grow: 1;
      }

      .cta {
        flex: 0 1 auto;
        align-self: flex-end;
      }
    }

    .event-img {
      @media (min-width: 768px) {
        display: flex;
        float: none;
        flex-direction: column;
        padding-left: 0;
        width: 270px;
      }
      @media (min-width: 992px) {
        width: 325px;
      }

      @media (max-width: 767px) {
        text-align: center;

        img {
          max-height: 133px;
        }
      }
    }

    .event-info-container {
      font-size: 13px;
      line-height: 16px;
      margin-left: 0;
      text-align: center;

      @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        float: none;
        font-size: 14px;
        line-height: 17px;
        padding-left: 5px;
        padding-right: 10px;
        text-align: left;;

        &.col-sm-12 {
          padding-left: 20px;
        }
      }

      .event-info {
        padding: 15px 0 10px;

        @media (min-width: 768px) {          
          padding: 10px 0;
        }

        .date {
          font-size: 13px;
          line-height: 16px;
          text-transform: uppercase;

          @media (min-width: 768px) {
            font-size: 14px;
            line-height: 17px;
          }
        }

      h3 {
        font-family: var(--font-text);
        font-size: 21px;
        line-height: 24px;
        margin: 10px 0 0;

        @media (max-width: 767px) {
          font-size: 18px;
          line-height: 21px;
        }
      }

        .event-performer-info {
          font-size: 13px;
          line-height: 15px;

          @media (min-width: 768px) {
            font-size: 12px;
          }
        }

        p {
          margin: 10px 0 0;
        }
      }

      .event-price {
        font-size: 18px;
        line-height: 21px;
        margin: 10px 0 0;
      }
    }

    h3, strong {
      color: var(--xs-events-event-heading-color);
    }
    
    h3 > a {
      color: var(--xs-events-link-color);
    }
  }

  > *:nth-child(n + 3) {
    @media (max-width: 767px) {
      display: none;
    }
  }

  > *:nth-child(n + 4) {
    @media (min-width: 768px) {
      display: none;
    }
  }

  &.in {
    > *:nth-child(n + 3) {
      @media (max-width: 767px) {
        display: block;
      }
    }

    > *:nth-child(n + 4) {
      @media (min-width: 768px) {
        display: block;
      }
    }
  }
}

.show-more {
  text-align: center;

  .events-show-more {
    background: var(--xs-events-event-background);
    border: var(--border-width) solid var(--xs-events-event-border);
    color: var(--xs-offer-view-more-color);
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    margin-top: 10px;
    padding: 20px;
    text-transform: uppercase;
    text-decoration: none;

    @media (max-width: 767px) {
      margin-top: 5px;
    }

    span,
    img {
      display: block;
      margin: 0 0 5px;
    }

    i.fa {
      font-family: FontAwesome;
      font-size: 24px;
      line-height: 15px;
      text-align: center;
    }
  }
}