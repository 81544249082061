body > .alert {
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;

  .container {
    padding: 0;
  }

  .item {
    padding: 0;
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 12px;
    line-height: 15px;

    @media (min-width: 768px) {
      font-size: 15px;
      line-height: 18px;
    }
  }

  a {
    display: block;
    padding: 10px 0;
    text-decoration: none;
    background: var(--xs-alert-background);
    color: var(--xs-alert-color);
  }

  strong {
    font-size: 13px;
    line-height: 16px;
    display: block;
    margin-bottom: 4px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 2px;
    }
  }
}
