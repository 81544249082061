/**
 *
 */

.navbar-collapse {
  top: auto;
  min-height: 40px;
}

.top {
  background: var(--xs-top-background);
  border: 0;
  margin-bottom: 0;
  position: relative;

  @media (min-width: 768px) {
    border-radius: 0;
    background: var(--sm-top-background);
  }

  @media (min-width: 992px) {
    background: var(--md-top-background);
  }

  a {
    color: var(--xs-top-link-color);
  
    &.phone {
      background: var(--xs-top-phone-button-background);
      color: var(--xs-top-phone-button-color);
      border: var(--border-width) solid var(--xs-top-phone-button-border);
      border-radius: 10px;
      padding: 19px 10px;
      white-space: normal;
      display: block;
      margin: 0 auto;
      font-weight: bold;
      line-height: 14px;
      text-align: center;

      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 24px;
        padding: 29px 22px;
      }

      &::before {
        content: "\f095";
        font-family: "FontAwesome";
        display: inline-block;
        text-decoration: none;
        padding-right: 3px;
        line-height: 14px;

        @media (min-width: 768px) {
          padding-right: 6px;
        }
      }
    }
  }

  .container {
    border: var(--border-width) solid var(--xs-top-border);

    @media (min-width: 768px) {
      position: relative;
      background: var(--sm-top-container-background);
    }

    @media (min-width: 992px) {
      background: var(--md-top-container-background);
    }

    &.phone {
      background: var(--xs-top-phone-background);
      border-color: var(--xs-top-phone-border);
      text-align: center;
      padding: 0px;

      a.phone {
        padding: 9px;
        font-size: 15px;
      }
    }
  }

  .logos {
    display: inline-block;
    padding: 10px 0 10px 61px;

    @media (min-width: 768px) {
      padding: 18px 0;
    }

    @media (min-width: 992px) {
      padding: 18px 28px;
    }
  }
  
  .navbar-header {
    padding: 0;
    line-height: 0;

    @media (min-width: 768px) and (max-width: 991px) {
      width: 100%;
      text-align: center;
    }

    @media (min-width: 992px) {
      padding: 0;
      float: none;
    }

    &.phone {
      @media (min-width: 768px) and (max-width: 991px) {
        text-align: left;
      }

      .logos {
        @media (min-width: 768px) and (max-width: 991px) {
          padding: 18px 0 18px 71px;
        }
      }
    }

    &.brands {
      @media (max-width: 767px) {
        padding-right: 61px;
      }

      @media (min-width: 768px) {
        float: none;
      }

      &.phone {
        @media (max-width: 767px) {
          text-align: center;
        }

        .navbar-right.phone {
          @media (max-width: 767px) {
            margin: 0;
          }
        }
      }

      .divider {
        float: left;
        height: var(--xs-brand-height);
        border-left: 1px solid var(--xs-top-divider-background);
        margin: 0 14px 0 16px;

        @media (min-width: 768px) {
          float: none;
          display: inline-block;
          height: var(--sm-brand-height);
          margin: 0 19px 0 20px;
        }
      }

      .navbar-right {
        @media (max-width: 767px) {
          float: none !important;
          text-align: center;
        }
      }

      a.phone {
        @media (max-width: 767px) {
          margin-top: 9px;
        }

        @media (min-width: 768px and max-width: 991px) {
          margin-top: 0;
        }

        &:hover {
          color: var(--xs-top-link-hover-color);
        }
      }
    }

    .row {
      margin: 0 -10px;

      .col-xs-6 {
        padding: 0 10px;
      }

      .col-xs-6:first-child {
        .brand {
          padding-left: 45px;
        }
      }

      .col-xs-6:last-child {
        text-align: right;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        margin-top: 13px;

        a.phone::before {
          font-family: "FontAwesome";
          content: "\f095";
          padding-right: 3px;
          display: inline-block;
          text-decoration: none;
        }
      }
    }

    .navbar-brand {
      background: var(--brand-1);
      background-size: contain;
      height: var(--xs-brand-height);
      width: var(--xs-brand-width);
      padding: 0;

      span {
        display: none;
      }

      @media (min-width: 768px) {
        height: var(--sm-brand-height);
        width: var(--sm-brand-width);
        display: inline-block;
        float: none;
        margin: 0;
      }

      &.brand-2 {
        background: var(--brand-2);
        background-size: contain;
      }

      &.brand-3 {
        background: var(--brand-3);
        background-size: contain;
      }
    }
  }

  .navbar-right {
    float: right !important;
    line-height: 14px;
    position: relative;

    @media (min-width: 768px) and (max-width: 991px) {
      margin: 0;
    }

    @media (min-width: 992px) {
      margin: 18px 24px 0 0;
    }

    &.phone {
      @media (min-width: 768px) {
        margin-right: -15px;
      }

      @media (min-width: 992px) {
        margin-top: 0;
      }

      &::before {
        content: " ";
        border-left: var(--border-width) solid var(--xs-top-divider-background);
        position: absolute;
        top: 8px;
        bottom: 8px;

        @media (min-width: 768px) {
          top: 18px;
          bottom: 18px;  
        }
      }
    }

    .navbar-text {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 12px;
      color: var(--md-top-color);
    }

    p {
      margin: 0;
    }

    .navbar-nav {
      line-height: 18px;

      &.utility {
        margin-bottom: 12px;
      }

      &.links {
        clear:  right;
      }

      &.links > li {
        > a {
          line-height: 20px;
          font-size: 15px;
        }
      }

      > li {
        display: inline-block;
        white-space: nowrap;
        line-height: 18px;

        @media (min-width: 992px) {
          float: none;
        }

        > a {
          line-height: 18px;
          font-size: 13px;
          color: var(--md-top-link-color);
          padding: 0;

          &:hover {
            color: var(--xs-top-link-hover-color);
            text-decoration: underline;
          }
        }

        &:last-child > a {
          padding-right: 2px;
        }

        &.divider {
          height: 18px;
          border-left: 1px solid var(--md-top-link-border);

          @media (min-width: 992px) {
            margin: 0 10px 0 11px;
            vertical-align: bottom;
          }
        }
      }
    }
  }
}

.navbar,
.navbar-inverse {
  .navbar-toggle {
    float: none;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    border-radius: 0;
    margin: 0;
    padding: 8px 10px;
    border: var(--border-width) solid var(--xs-hamburger-border);
    background: var(--xs-hamburger-background);
    display: block;

    @media (min-width: 768px) {
      top: calc(var(--border-width) * -1);
      left: calc(var(--border-width) * -1);
    }

    @media (min-width: 992px) {
      display: none;
    }

    .icon-bar {
      height: 4px;
      width: 24px;
      background: var(--xs-hamburger-color);
      border-radius: 0;
    }

    &:hover {
      background: var(--xs-menu-hover-background);
      border-color: var(--xs-menu-hover-border);

      .icon-bar {
        background: var(--xs-menu-hover-color);
      }
    }
  }
}
