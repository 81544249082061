.banner {
  text-align: center;
  background: var(--sm-banner-background);

  img {
    max-width: 100%;
  }
}

#banner {
  margin: 0 -15px;
}
