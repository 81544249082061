
.secondary-content-drawer {
  margin: 0;
  .more-information {
    padding: 16px 20px;

    .collapsible-title {
      color: var(--xs-secondary-content-more-info-color);
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.secondary-content {
  background: var(--xs-secondary-content-background);
  color: var(--xs-secondary-content-color);

  @media (min-width: 768px) {
    background: var(--sm-secondary-content-background);
  }

  .container {
    background: var(--sm-secondary-content-container-background);
    padding: 50px 30px 0;

    @media (max-width: 1199px) {
      padding: 40px 30px 0;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 30px 15px 0;
    }

    @media (max-width: 767px) {
      padding: 20px 20px 0;
    }

    .img-holder {
      img {
        max-width: 100%;
        margin-bottom: 20px;
        margin-top: 6px;

        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }
    }
  }

  h2 {
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    margin: 0 0 10px;

    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 26px;
      line-height: 30px;
    }

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 21px;
    }
  }
  
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 16px;
    
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 15px;
      line-height: 24px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;
    }

    a {
      color: var(--xs-secondary-content-link-color);

      &:hover {
        color: var(--xs-secondary-content-link-hover-color);
      }
    }
  }


  .btn-danger {
    color: var(--xs-secondary-content-button-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    border: var(--border-width) solid var(--xs-secondary-content-button-border);
    border-radius: 10px;
    background-color: var(--xs-secondary-content-button-background);
    padding: 14px 24px 14px;
    margin-bottom: 50px;
    width: 170px;

    @media (max-width: 1199px) {
      margin-bottom: 40px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      white-space: nowrap;
      margin-bottom: 30px;
    }

    @media (max-width: 767px) {
      line-height: 20px;
      margin-bottom: 20px;
    }

    &:hover {
      color: var(--xs-body-cta-button-hover-color);
      border-color: var(--xs-body-cta-button-hover-border);
      background-color: var(--xs-secondary-content-button-hover-background);
    }
  }
}
