.stars {
  display: inline-block;
  margin: 0 auto 9px;
  position: relative;
  height: 1em;
  line-height: 30px;
  font-size: 30px;
  letter-spacing: 2px;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 40px;
    margin: 0 0 0 5px;
  }

  .star-top,
  .star-bottom {
    font-family: 'Glyphicons Halflings';
    color: var(--xs-review-unstar-color);
  }

  .star-top {
    color: var(--xs-review-star-color);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 1em;
  }

  .star-top::before {
    display: inline-block;
    content: "\e006\e006\e006\e006\e006";
  }

  .star-bottom::before {
    display: inline-block;
    content: "\e007\e007\e007\e007\e007";
  }
}

.reviews {
  background: var(--xs-reviews-background);
  color: var(--xs-reviews-color);

  @media (min-width: 768px) {
    background: var(--sm-reviews-background);
  }

  a {
    color: var(--xs-reviews-link-color);
  }

  .container {
    padding: 20px;

    @media (min-width: 768px) {
      background: var(--sm-reviews-container-background);
      padding: 30px 15px 44px;
    }

    @media (min-width: 992px) {
      padding: 40px 30px 44px;
    }

    > h2 {
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      padding-bottom: 6px;

      @media (min-width: 768px) {
        margin: 0;
        padding-bottom: 10px;
        font-size: 26px;
        line-height: 30px;
      }
    }

    > p {
      color: var(--xs-review-color);
      font-size: 14px;
      line-height: 21px;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
      }

      @media (min-width: 992px) {
        margin-bottom: 30px;
      }
    }
  }

  .review-rating {
    @media (min-width: 992px) {
      width: 910px;
      margin: 0 auto;
    }
  }

  .panel {
    border-radius: 0;
    box-shadow: none;
    text-align: center;
    margin-bottom: 10px;
    padding: 20px;
    border: var(--border-width) solid var(--xs-reviews-border);
    color: var(--xs-review-color);
    background: var(--xs-review-background);

    @media (min-width: 768px) {
      text-align: left;
      padding: 13px 13px 18px;
    }

    .col-sm-8 {
      @media (min-width: 768px) {
        width: 505px;
      }

      @media (min-width: 992px) {
        width: 630px;
      }
    }

    .col-sm-4 {
      @media (min-width: 768px) {
        margin: 13px 15px 0 0;
        float: right;
        width: 190px;
      }

      @media (min-width: 992px) {
        width: 230px;
      }
    }

    .panel-heading {
      padding: 0;
      border: 0;
      background: transparent;

      .panel-title {
        color: var(--xs-review-title-color);
        line-height: 21px;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 7px;

        @media (min-width: 768px) {
          padding-bottom: 10px;
        }
      }
    }

    .btn {
      width: 170px;
      padding: 14px 17px 14px 20px;
      line-height: 18px;
      border-radius: 10px;

      @media (min-width: 992px) {
        padding: 14px 30px;
        width: 200px;
      }
    }

    .panel-body {
      padding: 0;
      line-height: 16px;
      font-size: 13px;

      &.no-reviews {
        font-size: 13px;
        font-weight: bold;
        line-height: 16px;
        padding-bottom: 10px;

        @media (min-width: 768px) {
          padding: 32px 27px 27px;
        }
      }

      &.aggregate {
        > p {
          margin: 0 0 15px;

          @media (min-width: 768px) {
            display: inline-block;
            vertical-align: top;
            margin: 9px 0 0 6px;
          }
        }
      }
    }

    .col-sm-12 {
      text-align: center;

      .panel-body.no-reviews {
        @media (max-width: 767px) {
          padding-bottom: 0px;
        }
      }
    }
  }

  .rating {
    @media (min-width: 768px) {
      display: inline-block;
    }
  }

  #reviews {
    hr {
      border-top: var(--border-width) solid var(--sm-review-divider-background);
      margin: 0 15px 4px;

      @media (min-width: 768px) {
        margin: 4px 15px;
      }
    }

    .page {
      &:nth-child(n + 2) {
        @media (max-width: 767px) {
          display: none;
        }
      }

      &:nth-child(n + 4) {
        @media (min-width: 768px) {
          display: none;
        }
      }
    }

    .panel {
      border-color: var(--xs-review-border);
      padding: 18px;
      margin-bottom: 4px;

      @media (min-width: 768px) {
        margin: 0;
      }

      .panel-heading {
        .panel-title {
          line-height: 19px;
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 5px;

          @media (min-width: 768px) {
            display: inline-block;
            vertical-align: top;
            margin: 2px 0 0 6px;
            font-size: 18px;
            line-height: 21px;
          }
        }
      }

      .panel-body {
        p {
          @media (min-width: 768px) {
            font-size: 14px;
            line-height: 17px;
          }

          &.review {
            text-align: left;
            margin-bottom: 2px;

            @media (min-width: 768px) {
              line-height: 18px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .stars {
      margin-bottom: 10px;
      line-height: 23px;
      font-size: 23px;

      @media (min-width: 768px) {
        line-height: 25px;
        font-size: 25px;
        margin-bottom: 8px;
      }
    }

    .paginate-after,
    .paginate-before {
      display: none;
    }

    nav ul.pager {
      margin: 0;
      padding: 11px 11px 9px;
      border: var(--border-width) solid var(--xs-reviews-border);
      background: var(--xs-review-background);
      text-align: center;

      @media (min-width: 768px) {
        margin-top: 10px;
      }

      li {
        padding: 0 10px;
        width: auto;
        float: none;
        display: inline-block;

        a,
        .fa {
          font-size: 20px;
          line-height: 18px;
          font-weight: bold;
        }

        a {
          padding: 0;
          border: 0;
          background: transparent;
          text-decoration: none;
        }

        &.disabled a {
          color: var(--xs-review-disabled);
        }

        &:first-child {
          padding: 0 8px 0 0;
        }

        &:last-child {
          padding: 0 0 0 8px;
        }
      }
    }
  }

  #reviews {
    @media (max-width: 767px) {
      text-align: center;

      .btn {
        margin: 20px auto 0;
        font-size: 16px;
        line-height: 18px;
        width: 200px;
        padding: 14px 32px 14px 35px;
        border-radius: 10px;
      }
    }
  }

  .btn {
    color: var(--xs-reviews-button-color) !important;
    background-color: var(--xs-reviews-button-background);
    border-color: var(--xs-reviews-button-border);

    &:hover {
      color: var(--xs-reviews-button-hover-color) !important;
      background-color: var(--xs-reviews-button-hover-background);
      border-color: var(--xs-reviews-button-hover-border);
    }
  }

  .reviews-show-more {
    margin-top: 10px;
    padding: 20px;
    background-color: var(--xs-review-more-background);
    text-align: center;
    border: var(--border-width) solid var(--xs-reviews-border);    
    display: block;
    color: var(--xs-review-view-more-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    text-transform: uppercase;
    text-decoration: none;

    span,
    img {
      display: block;
      margin: 0 0 5px;
    }

    i.fa {
      font-family: FontAwesome;
      font-size: 24px;
      line-height: 15px;
      text-align: center;
    }
  }
}
