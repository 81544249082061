/**
 *
 */

.footer {
  background: var(--xs-footer-background);
  border: 0;
  border-radius: 0;
  margin: 0;
  font-size: 13px;

  @media (max-width: 991px) {
    text-align: center;
  }

  @media (min-width: 768px) {
    background: var(--sm-footer-background);
  }

  a {
    color: var(--xs-footer-color);
  }

  hr {
    margin: 0;
    border-top: 1px solid var(--xs-footer-rule-color);

    @media (min-width: 992px) {
      margin: 0 0 20px;
    }
  }

  .container {
    padding: 18px;
    border: var(--border-width) solid var(--xs-footer-border);

    @media (min-width: 768px) {
      background: var(--sm-footer-container-background);
    }

    @media (min-width: 992px) {
      padding: 19px 28px 18px;
    }

    .navbar-brand {
      margin-top: 20px;
      display: inline-block;
      float: none;
      padding: 0;
      background-size: contain;
      height: var(--md-menu-brand-height);
      width: var(--md-menu-brand-width);

      @media (min-width: 768px) {
        margin-top: 15px;
      }

      @media (min-width: 992px) {
        margin: 20px 0 0;
      }

      span {
        display: none;
      }
    }
  }

  .nav > li {
    display: inline-block;
    white-space: nowrap;
    float: none;

    a {
      color: var(--xs-footer-color);
    }
  }

  .menu ul, ul.disclaimers {
    display: inline-block;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;

    @media (min-width: 992px) {
      text-align: left;
      padding-left: 0;
      padding-right: 0;
    }

    > li {
      display: inline;
      white-space: normal;

      &::after {
        content: " ";
        letter-spacing: 16px;
        background-image: linear-gradient(90deg, transparent calc(50% - 1px), var(--xs-footer-color), transparent calc(50% + 1px));
        background-size: 1em 1em;
        background-position: center center;
        background-repeat: no-repeat;

        @media (min-width: 768px) {
          letter-spacing: 21px;
        }
      }

      a {
        white-space: nowrap;
        text-decoration: none;
        display: inline;
      }
    }
  }

  ul.disclaimers > li::after {
    letter-spacing: 18px;
  }

  .disclaimers {
    margin: 0 auto;
    padding: 19px 0 0;
    float: none;

    @media (min-width: 992px) {
      padding-top: 36px;
    }

    > li a {
      line-height: 18px;
      padding: 0;
      text-decoration: none;
    }
  }

  .menu {
    padding: 19px 20px 0;
    font-size: 14px;

    @media (min-width: 768px) {
      padding: 20px 20px 6px;
      font-size: 15px;
    }

    @media (min-width: 992px) {
      padding: 0 15px;
      font-size: 16px;
    }

    .navbar-nav {
      margin: 0;
      float: none;

      > li a {
        line-height: 30px;
        padding: 0;
        text-decoration: none;

        @media (min-width: 768px) {
          line-height: 24px;
        }
      }
    }
  }

  .navbar-text {
    margin: 5px 0 20px;
    color: var(--xs-footer-color);

    &.legal {
      font-size: 12px;
      line-height: 15px;

      @media (min-width: 992px) {
        width: 500px;
        float: none;
      }

      @media (min-width: 992px) {
        width: 500px;
      }

      @media (min-width: 1200px) {
        width: 700px;
      }
    }

    > p {
      color: var(--xs-footer-legal-color);
      font-size: 11px;
      line-height: 14px;
      margin: 0 0 5px;

      @media (min-width: 768px) {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}
