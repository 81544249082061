/**
 *
 */

.header {
  .col-md-4 {
    @media (min-width: 992px) {
      width: 350px;
    }

    @media (min-width: 1200px) {
      width: 390px;
    }
  }

  .cta {
    background: var(--xs-cta-background);
    color: var(--xs-cta-color);
    border: var(--border-width) solid var(--xs-cta-border);

    a {
      color: var(--xs-cta-color);

      &:hover {
        color: var(--xs-cta-hover-color);
      }
    }

    @media (min-width: 768px) {
      padding: 0;
    }

    .header-cta {
      padding: 13px 3px;

      @media (min-width: 768px) {
        padding: 23px 13px;
      }

      @media (min-width: 992px) {
        padding: 28px;
      }
    }

    strong {
      font-size: 20px;
      line-height: 21px;
      font-weight: 600;
      display: block;
      font-family: var(--font-heading);

      @media (min-width: 768px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    p {
      font-size: 14px;
      line-height: 17px;
      margin: 0;
      padding: 2px 0 0;

      @media (min-width: 768px) {
        padding-top: 9px;
        font-size: 16px;
        line-height: 20px;
      }

      @media (min-width: 992px) {
        padding-top: 10px;
      }
    }

    .btn {
      background: var(--xs-cta-button-background);
      color: var(--xs-cta-button-color) !important;
      border: var(--border-width) solid var(--xs-cta-button-border);
      border-radius: 10px;
      padding: 12px 11px 14px;
      white-space: normal;
      width: 170px;
      margin: 10px 0 0;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;

      @media (min-width: 768px) {
        margin-top: 19px;
        font-size: 18px;
        width: 196px;
      }

      @media (min-width: 992px) {
        margin-top: 23px;
      }

      @media (min-width: 1200px) {
        margin-top: 25px;
      }

      &:hover {
        background: var(--xs-cta-button-hover-background);
        border-color: var(--xs-cta-button-hover-border);
        color: var(--xs-cta-button-hover-color) !important;
      }
    }
  }
}
