/**
 *
 */

@media (max-width: 767px) {
  .respond-xs {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .respond-sm {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .respond-md {
    display: none;
  }
}

@media (min-width: 1200px) {
  .respond-lg {
    display: none;
  }
}
