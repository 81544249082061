.email-signup {
  background: var(--xs-email-signup-background);
  text-align: center;
  color: var(--xs-email-signup-color);
  border: var(--border-width) solid var(--xs-email-signup-panel-border);

  @media (min-width: 768px) {
    background: var(--sm-email-signup-background);
  }

  .container {
    padding: 20px 10px;
    border: var(--border-width) solid var(--xs-email-signup-panel-border);

    @media (min-width: 768px) {
      padding: 30px calc(30px - var(--border-width)) 31px;
      background: var(--sm-email-signup-container-background);
    }

    @media (min-width: 992px) {

    }

    @media (min-width: 1200px) {
      padding: 30px calc(130px - var(--border-width)) 31px;
    }
  }

  .panel {
    background: var(--xs-email-signup-panel-background);
    border: var(--border-width) solid var(--xs-email-signup-panel-border);
    border-radius: 0;
    margin: 0;
    padding: 16px 8px 13px;

    @media (min-width: 768px) {
      padding: 23px 28px;
    }

    .panel-heading {
      border: 0;
      padding: 0;
      background: transparent;

      h3 {
        color: var(--xs-email-signup-color);
        margin: 0 0 2px;
        font-size: 18px;
        line-height: 21px;
        font-weight: bold;

        @media (min-width: 768px) {
          margin: 0 0 4px;
          font-size: 21px;
          line-height: 24px;
        }
      }
    }

    a {
      color: var(--xs-email-signup-panel-link-color);

      &:hover {
        color: var(--xs-email-signup-panel-link-hover-color);
      }
    }

    .panel-body {
      padding: 0;
      font-size: 14px;
      line-height: 17px;

      @media (min-width: 768px) {
        line-height: 18px;
      }

      form, .form {
        margin: 10px 8px 0;

        @media (min-width: 768px) {
          margin: 23px 0 0;
        }

        .form-group {
          margin-bottom: 10px;

          @media (min-width: 768px) {
            margin: 0;
          }
        }

        input {
          height: auto;
          line-height: 16px;
          font-size: 14px;
          font-family: Arial;
          padding: 15px 15px 17px;
          border-radius: 0;
          background: var(--xs-email-signup-input-background);
          border-color:  var(--xs-email-signup-input-border);
          color: var(--xs-email-signup-color);
          
          &::placeholder {
            color: var(--xs-email-signup-input-color);
          }

          @media (min-width: 768px) {
            width: 320px;
            margin-right: 6px;
          }
        }

        .btn {
          width: 170px;
          padding: 14px 30px 14px 31px;
          line-height: 18px;
          font-size: 16px;
          background: var(--xs-email-signup-button-background);
          border-color: var(--xs-email-signup-button-border);
          color: var(--xs-email-signup-button-color) !important;

          &:hover {
            background: var(--xs-email-signup-button-hover-background);
            border-color: var(--xs-email-signup-button-hover-border);
            color: var(--xs-email-signup-button-hover-color) !important;
          }
        }
      }
    }
  }
}
